import { useLibraryTabs } from 'libs/utils/src/lib/library-tabs.context';
import Dropdown from '../../dropdown/dropdown';
import styles from './library-switch.module.css';
import { useLibraryTabsOptions } from '../../library-tabs/tabs';

export function LibrarySwitch() {
    const { library, setLibrary, isSwitcherVisible } = useLibraryTabs();

    const options = useLibraryTabsOptions();

    if (!isSwitcherVisible) {
        return null;
    }

    return (
        <Dropdown
            buttonClassname={styles['header-library-dropdown__dropdown-button']}
            containerClassname={styles['header-library-dropdown__dropdown-container']}
            optionContainerClassname={styles['header-library-dropdown__dropdown-options']}
            optionClassname={styles['header-library-dropdown__dropdown-option-button']}
            value={library}
            options={options}
            onClick={setLibrary}
            disabled={!isSwitcherVisible}
            layoutType="border-input"
        />
    );
}
